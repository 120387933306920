<template>
    <div>
        <v-container class="paper-border">
            <v-row>
                <v-col cols="4" class="d-flex flex-column py-0">
                    <span class="font-weight-bold">{{ $t('message.buyer') }}</span>
                    <span :class="Pldo__Customer__title ? '' : 'red--text'">{{ Pldo__Customer__title ? Pldo__Customer__title : $t('message.valueMissing') }}</span>
                </v-col>
                <v-col cols="4" class="d-flex flex-column py-0">
                    <span class="font-weight-bold">{{ $t('message.containers') }}</span>
                    <span :class="Pldo__Containers.length > 0 ? '' : 'red--text'">{{ Pldo__Containers.length > 0 ? containerNumbers : $t('message.valueMissing') }}</span>
                </v-col>
                <v-col cols="4" class="d-flex flex-column py-0">
                    <span class="font-weight-bold">{{ $t('message.date') }}</span>
                    <span :class="Pldo__pldodate ? '' : 'red--text'">{{ Pldo__pldodate ? formatDate(Pldo__pldodate) : $t('message.valueMissing') }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="d-flex flex-column pt-1 pb-0 offset-4">
                    <span class="font-weight-bold">{{ $t('message.grossWeight') }}</span>
                    <span :class="Pldo__grossweight > 0 ? '' : 'red--text'">
                        {{ Pldo__grossweight > 0 ? formatThisNumber(Pldo__grossweight,uofmWeights.find((uofm)=>uofm.Measurement.id == Pldo__gwmeasurement_id).Measurement.formatPrecision) + ' ' + uofmWeights.find((uofm)=>uofm.Measurement.id == Pldo__gwmeasurement_id).Measurement.title : $t('message.valueMissing') }}
                    </span>
                </v-col>
                <v-col cols="4" class="d-flex flex-column pt-1 pb-0">
                    <span class="font-weight-bold">{{ $t('message.invoiceNumber') }}</span>
                    <span :class="Pldo__Invoice__invno ? '' : 'red--text'">{{ Pldo__Invoice__invno ? Pldo__Invoice__invno : $t('message.valueMissing') }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="d-flex flex-column pt-1 pb-0 offset-4">
                    <span class="font-weight-bold">{{ $t('message.netWeight') }}</span>
                    <span :class="Pldo__netweight > 0 ? '' : 'red--text'">
                        {{ Pldo__netweight > 0 ? formatThisNumber(Pldo__netweight,uofmWeights.find((uofm)=>uofm.Measurement.id == Pldo__nwmeasurement_id).Measurement.formatPrecision) + ' ' + uofmWeights.find((uofm)=>uofm.Measurement.id == Pldo__nwmeasurement_id).Measurement.title : $t('message.valueMissing') }}
                    </span>
                </v-col>
                <v-col cols="4" class="d-flex flex-column pt-1 pb-0">
                    <span class="font-weight-bold">{{ $t('message.contractNumber') }}</span>
                    <span :class="Pldo__Contract__title ? '' : 'red--text'">
                        {{ Pldo__Contract__title != '' ? Pldo__Contract__title : $t('message.valueMissing') }}
                    </span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="d-flex flex-column pt-1 pb-0 offset-4" v-if="[2,3,4].indexOf(Pldo__Contract__fsc) != -1">
                    <span class="font-weight-bold">{{ $t('message.fscNo') }}</span>
                    <span :class="Pldo__Fsc__no ? '' : 'red--text'">
                        {{ Pldo__Fsc__no ? Pldo__Fsc__no : $t('message.valueMissing') }}
                    </span>
                </v-col>
                <v-col cols="4" class="offset-4" v-else></v-col>
                <v-col cols="4" class="d-flex flex-column pt-1 pb-0">
                    <span class="font-weight-bold">{{ $t('message.vesselName') }}</span>
                    <span :class="Pldo__Shipment__vessel ? '' : 'red--text'">
                        {{ Pldo__Shipment__vessel ? Pldo__Shipment__vessel : $t('message.valueMissing') }}
                    </span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="d-flex flex-column pt-1 pb-0 offset-4" v-if="[2,3,4].indexOf(Pldo__Contract__fsc) != -1">
                    <span class="font-weight-bold">{{ $t('message.fscExpiryDate') }}</span>
                    <span :class="Pldo__Fsc__expiry_date ? '' : 'red--text'">
                        {{ Pldo__Fsc__expiry_date ? Pldo__Fsc__expiry_date : $t('message.valueMissing') }}
                    </span>
                </v-col>
                <v-col cols="4" class="offset-4" v-else></v-col>
                <v-col cols="4" class="d-flex flex-column pt-1 pb-0">
                    <span class="font-weight-bold">{{ $t('message.etd') }}</span>
                    <span :class="Pldo__Shipment__etddate != null && Pldo__Shipment__etddate != '0000-00-00' ? '' : 'red--text'">
                        {{ Pldo__Shipment__etddate != null && Pldo__Shipment__etddate != '0000-00-00' ? formatDate(Pldo__Shipment__etddate) : $t('message.valueMissing') }}
                    </span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="d-flex flex-column pt-1 pb-0 offset-8">
                    <span class="font-weight-bold">{{ $t('message.eta') }}</span>
                    <span :class="Pldo__Shipment__etadate != null && Pldo__Shipment__etadate != '0000-00-00' ? '' : 'red--text'">
                        {{ Pldo__Shipment__etadate != null && Pldo__Shipment__etadate != '0000-00-00'  ? formatDate(Pldo__Shipment__etadate) : $t('message.valueMissing') }}
                    </span>
                </v-col>
            </v-row>
            <v-simple-table
                    class="pt-2"
                    dense
            >
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="px-0 subtitle-1 font-weight-black grey lighten-4">{{ $t('message.container') }}</th>
                            <th class="px-0 subtitle-1 font-weight-black grey lighten-4 width-15-pct">{{ $t('message.specification') }}</th>
                            <th class="px-0 subtitle-1 font-weight-black grey lighten-4">{{ $t('message.grade') }}</th>
                            <th class="px-0 subtitle-1 font-weight-black grey lighten-4">{{ $t('message.type') }}</th>
                            <th class="px-0 subtitle-1 font-weight-black grey lighten-4">{{ $t('message.thickness') }}</th>
                            <th class="px-0 subtitle-1 font-weight-black grey lighten-4">{{ $t('message.width') }}</th>
                            <th class="px-0 subtitle-1 font-weight-black grey lighten-4">{{ $t('message.length') }}</th>
                            <th class="px-0 subtitle-1 font-weight-black grey lighten-4 text-right">{{ $t('message.bundles') }}</th>
                            <th class="px-0 subtitle-1 font-weight-black grey lighten-4 text-right">{{ $t('message.pieces') }}</th>
                            <th class="px-0 subtitle-1 font-weight-black grey lighten-4 text-right" v-if="itemsTotalArea.length > 0">{{ $t('message.area') }}</th>
                            <th class="px-0 subtitle-1 font-weight-black grey lighten-4 text-right">{{ $t('message.volume') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in PackingListItems" :key="index">
                            <td :class="(item.Container.title == '' ? 'red--text ' : '') + 'px-0 border-0'">{{ item.Container.title ? item.Container.title : $t('message.valueMissing') }}</td>
                            <td :class="(item.Salesdescription.title == '' ? 'red--text ' : '') + 'px-0 border-0'">{{ item.Salesdescription.title ? item.Salesdescription.title : $t('message.valueMissing') }}</td>
                            <td :class="(item.Grade.title == null ? 'red--text ' : '') + 'px-0 border-0'">{{ item.Grade.title ? item.Grade.title : $t('message.valueMissing') }}</td>
                            <td :class="(item.PackingListItem.type == null ? 'red--text ' : '') + 'px-0 border-0'">{{ item.PackingListItem.type ? item.PackingListItem.type : $t('message.valueMissing') }}</td>
                            <td :class="(item.PackingListItem.thickness == '' ? 'red--text ' : '') + 'px-0 border-0'">{{ item.PackingListItem.thickness ? item.PackingListItem.thickness + ' ' + uofmDimensions.find((uofm) => uofm.Measurement.id == item.PackingListItem.smeasurement_id).Measurement.title : $t('message.valueMissing') }}</td>
                            <td :class="(item.PackingListItem.width == '' ? 'red--text ' : '') + 'px-0 border-0'">{{ item.PackingListItem.width ? item.PackingListItem.width + ' ' + uofmDimensions.find((uofm) => uofm.Measurement.id == item.PackingListItem.wmeasurement_id).Measurement.title : $t('message.valueMissing') }}</td>
                            <td :class="(item.PackingListItem.length == '' ? 'red--text ' : '') + 'px-0 border-0'">{{ item.PackingListItem.length ? item.PackingListItem.length + ' ' + uofmDimensions.find((uofm) => uofm.Measurement.id == item.PackingListItem.lmeasurement_id).Measurement.title : $t('message.valueMissing') }}</td>
                            <td class="px-0 text-right border-0">{{ formatThisNumber(item.PackingListItem.bundles,'0,0') }}</td>
                            <td class="px-0 text-right border-0">{{ formatThisNumber(item.PackingListItem.pieces,'0,0') }}</td>
                            <td class="px-0 text-right border-0" v-if="item.PackingListItem.area">{{ formatThisNumber(item.PackingListItem.area,uofmAreas.find((uofm)=>uofm.Measurement.id == item.PackingListItem.ameasurement_id).Measurement.formatPrecision) + ' ' + uofmAreas.find((uofm)=>uofm.Measurement.id == item.PackingListItem.ameasurement_id).Measurement.title }}</td>
                            <td class="px-0 text-right border-0">{{ formatThisNumber(item.PackingListItem.volume,uofmVolumes.find((uofm)=>uofm.Measurement.id == item.PackingListItem.shippedmeasurement_id).Measurement.formatPrecision) + ' ' + uofmVolumes.find((uofm)=>uofm.Measurement.id == item.PackingListItem.shippedmeasurement_id).Measurement.title }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td class="border-top-2"></td>
                            <td class="border-top-2"></td>
                            <td class="border-top-2"></td>
                            <td class="border-top-2"></td>
                            <td class="border-top-2"></td>
                            <td class="border-top-2"></td>
                            <td class="border-top-2"></td>
                            <td class="px-0 text-right font-weight-bold border-top-2">{{ formatThisNumber(itemsTotalBundles,'0,0') }}</td>
                            <td class="px-0 text-right font-weight-bold border-top-2">{{ formatThisNumber(itemsTotalQty,'0,0') }}</td>
                            <td class="px-0 text-right font-weight-bold border-top-2" v-if="itemsTotalArea.length > 0">
                                <span v-for="(total,index) in itemsTotalArea">
                                        {{ formatThisNumber(total.Total.area, uofmAreas.find((uofm) => uofm.Measurement.title == total.Total.uofm.trim()).Measurement.formatPrecision) + ' '+ total.Total.uofm.trim() }}
                                </span>
                            </td>
                            <td class="px-0 text-right font-weight-bold border-top-2">
                                <span v-for="(total,index) in itemsTotalVolume">
                                        {{ formatThisNumber(total.Total.volume, uofmVolumes.find((uofm) => uofm.Measurement.title == total.Total.uofm.trim()).Measurement.formatPrecision) + ' '+ total.Total.uofm.trim() }}
                                </span>
                            </td>
                        </tr>
                    </tfoot>
                </template>
            </v-simple-table>
        </v-container>
    </div>
</template>

<script>
    import {mapFields, mapMultiRowFields} from "vuex-map-fields";
    import {mapActions, mapGetters} from "vuex";
    import {formatDate, numberFormat} from "../../helpers/helpers";
    import {mapFieldsPrefixed, mapMultiRowFieldsPrefixed} from "../../helpers/vuex-map-fields-prefixed";

    export default {
        name: "PackingListPreview",
        data(){
            return {

            }
        },
        computed: {
            statePrefix(){
                if(this.updateMode){
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapGetters('buyer',{
                'findBuyerById': 'findBuyerById'
            }),
            ...mapFieldsPrefixed('packinglist', {
                Pldo__Containers: 'Pldo.Containers',
                Pldo__Contract__fsc: 'Pldo.Contract.fsc',
                Pldo__Contract__title: 'Pldo.Contract.title',
                Pldo__Customer__title: 'Pldo.Customer.title',
                Pldo__Invoice__invno: 'Pldo.Invoice.invno',
                Pldo__pldodate: 'Pldo.pldodate',
                Pldo__id: 'Pldo.id',
                Pldo__contract_id: 'Pldo.contract_id',
                Pldo__grossweight: 'Pldo.grossweight',
                Pldo__gwmeasurement_id: 'Pldo.gwmeasurement_id',
                Pldo__netweight: 'Pldo.netweight',
                Pldo__nwmeasurement_id: 'Pldo.nwmeasurement_id',
                Pldo__pl_title: 'Pldo.pl_title',
                Pldo__title: 'Pldo.title',
                Pldo__Shipment__etadate: 'Pldo.Shipment.etadate',
                Pldo__Shipment__etddate: 'Pldo.Shipment.etddate',
                Pldo__Shipment__vessel: 'Pldo.Shipment.vessel',
            },'statePrefix'),
            ...mapMultiRowFieldsPrefixed('packinglistitem', {
                PackingListItems: 'PackingListItems'
            },'statePrefix'),
            ...mapFieldsPrefixed('packinglistitem',{
                itemsTotalArea: 'itemsTotalArea',
                itemsTotalBundles: 'itemsTotalBundles',
                itemsTotalQty: 'itemsTotalQty',
                itemsTotalVolume: 'itemsTotalVolume',
            },'statePrefix'),
            ...mapGetters([
                'certifications',
                'uofmAreas',
                'uofmDimensions',
                'uofmQty',
                'uofmVolumes',
                'uofmWeights'
            ]),
            containerNumbers () {
                let containers = []
                this.Pldo__Containers.forEach((item) => {
                    containers.push(item.Container.title)
                })
                return containers.join(', ')
            },
            Pldo__Fsc__no () {
                if( [2,3,4].indexOf(this.Pldo__Contract__fsc) != -1) {
                    return this.certifications.find((certification) => certification.Certification.id == this.Pldo__Contract__fsc)['Certification']['cert_no']
                } else {
                    return null
                }
            },
            Pldo__Fsc__expiry_date () {
                if( [2,3,4].indexOf(this.Pldo__Contract__fsc) != -1) {
                    return this.certifications.find((certification) => certification.Certification.id == this.Pldo__Contract__fsc)['Certification']['cert_expiry_date']
                } else {
                    return null
                }
            },
            updateMode: function () {
                return this.$route.matched.some(({name}) => name === 'update_packinglist')
            },
        },
        methods: {
            ...mapActions('packinglist',{
                savePackingList: 'savePackingList'
            }),
            createSpecification(item){
                let specification = '';
                if(item.type == 'othercharge'){
                    specification = item.Grade.title;
                } else if(item.type == 'credit'){
                    specification = item.Spec.title
                } else if(item.type == 'default'){
                    specification = `${item.Species.title ? item.Species.title + ', ' : ''} ${item.Spec.title}`
                }
                return specification
            },
            formatDate,
            formatThisNumber(value,format){
                return numberFormat(value,format)
            },
        },
        created(){}
    }
</script>

<style>
    .paper-border {
        border: dashed 1px darkgrey;
    }
    .border-top-2 {
        border-top: 2px solid black;
    }
    .border-bottom-2 {
        border-bottom: 2px solid black !important;
    }
</style>